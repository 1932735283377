<template>
    <!--<vs-upload automatic action="https://api.studioconnect.fr/b4e962cd-ce98-4d9e-b201-3974c37711db/studio/cloud" @on-success="successUpload" />-->
    <div class="upload">
        <label>File
            <input type="file" id="file" v-on:change="handleFileUpload()"/>
        </label>
        <button v-on:click="submitFile()" class="btn">Envoyé</button>
    </div>
</template>


<style lang="scss">
.upload{
    .btn{
        padding:5px;
        padding-left:10px;
        padding-right:10px;
    }
}
</style>



<script>
export default {
    components:{
    },
    props:{
        url:{
            type:String,
            default: 'studio/cloud',
        },
    },
    data(){
        return{
            //file:'',
        }
	},
	mounted:function()
	{

    },
    watch:{
        $route (){
            
        }
    },
    methods:{ 
        handleFileUpload:function()
        {
            //this.file = this.$refs.file.files[0];
        },
        submitFile:function()
        {
            //console.log( document.getElementById('file').files )
            //console.log( this.url )
            if(document.getElementById('file').files[0] == undefined)
            {
                alert('Aucun fichier choisi !')
                return
            }

            this.$srvApi.ReqUpload( this.url, document.getElementById('file').files[0] )
            .then((msg)=>{
                //Message d'erreur
                if(msg.status!='success')
                    alert(msg.message)
                else
                    this.$emit('refresh')
            })
            .catch((err)=>{
                //console.log(err)
                alert('Err upload !',err)
            })
        }
    }
};

</script>