<template>
    <div class="listDate">
        <div v-for="data in tab" :key="data.key">
            <template v-if="sep">
                <vs-divider>{{ data.name }}</vs-divider>
            </template>
            <div v-for="(item, key, index) in data.data" :key="index">
                <slot name="item" v-bind:item="item"></slot>
            </div>
        </div>
        <template v-if="tab.length==0">
            <slot name="empty"></slot>
        </template>
    </div>
</template>

<style lang="scss">
.listDate{

}
</style>

<script>
import Fdate from '@/assets/utils/fdate';


export default {
    components:{
	},
    props: {
        reverse:false,
        empty:true,
        sep:true,
    },
    data(){
        return{
            tab:[],
        }
    },
    methods:{
        clear:function()
        {
            this.tab = []
        },

        //AFFICHAGE ANNEE
        addTabYear:function( data )
        {
            //Etape 0: trier par date
            data = this.tabInputTriage(data)

            //get date
            let year = (new Date()).getFullYear()
            if(data[0])
                year = (new Date(data[0].date)).getFullYear()

            //création conteneur
            this.clear()
            let compte = 0;
            for( var i=1; i<13; i++)
            {
                let month_string = Fdate.getMonth("2000-"+i+'-01 00:00:01')+' '+year
                this.tab.push({
                    key   : compte,
                    month : i,
                    name  : month_string,
                    data  : [],
                    year  : year,
                })
                compte++
            }
            
            //Ajout du contenu
            this.addData(data)

        },


        // AJOUT EN MODE MONTH SANS TROP
        addTabMonth:function( data )
        {
            //Etape 0: trier par date
            data = this.tabInputTriage(data)

            if(data[0] == undefined)
            {
                this.tab = []
                return
            }

            //création conteneur
            this.clear()

            let date_actu  = new Date( data[0].date )
            date_actu.setDate(1);
            date_actu.setHours(0);
            date_actu.setMinutes(0);
            date_actu.setSeconds(0);

            let date_fin   = (new Date( data[ data.length-1 ].date ));

            //Add 1 month
            //date_fin.setMonth( date_fin.getMonth()+1 );

            let time_fin   = date_fin.getTime()

            let tab_month = Fdate.getTabMonth();

            //console.log(data);
            //console.log("ICICICI");

            //Fonction boucle
            let fonc = ( callback )=>
            {
                let b_year  = date_actu.getFullYear();
                let b_month = date_actu.getMonth();
                    
                let month_string = tab_month[b_month]+' '+b_year;
                //console.log( month_string+" /// "+b_year+"/"+b_month )
                
                //Add elem
                this.tab.push({
                    month : b_month,
                    name  : month_string,
                    data  : [],
                    year  : b_year,
                })

                //Add 1 month
                //console.log( date_actu );
                date_actu.setMonth( date_actu.getMonth()+1 );
                //console.log( date_actu );

                //Fin naturel
                if( date_actu.getTime() < time_fin )
                    fonc( callback )
                else
                    callback();
            };

            fonc(()=>{
                //console.log('FIN')
                //console.log( this.tab )
                
                //Ajout du contenu
                this.addData(data)
            })
        },







        addData:function( data )
        {
            //console.log("=======================")
            //console.log( data );
            //Ajout du contenu
            for( var p=0; p<data.length; p++)
            {
                let d     = new Date(data[p].date);
                let month = d.getMonth()
                let year  = d.getFullYear()
                //console.log( year+" "+month );

                //console.log( data[p] );
                for( var n=0; n<this.tab.length; n++)
                {
                    //console.log( this.tab[n].month+"=="+month+" / "+this.tab[n].year+" == "+year );
                    if(this.tab[n].month == month )
                    if(this.tab[n].year == year)
                    {
                        //console.log("oki")
                        this.tab[n].data.push({
                            data  : data[p].data,
                            month : month,
                            year  : this.tab[n].year,
                        })
                        break;
                    }
                }
            }

            //reverse ?
            if(this.reverse)
            {
                //triage month
                this.tab.reverse();

                //triage month conteneur
                for( var t in this.tab )
                this.tab[t].data.reverse();
            }

            //vider les vides ?
            if(this.empty=="false")
            {
                let buffer = []
                for( var i=0; i<this.tab.length; i++)
                if(this.tab[i].data.length>0)
                    buffer.push(this.tab[i])
                this.tab = buffer
            }
        },
        tabInputTriage:function(data)
        {
            //trier par date
            data.sort(function(a, b)
            {
                var time_a  = (new Date(a.date)).getTime()
                var time_b  = (new Date(b.date)).getTime()
                return time_a - time_b;
            });
            return data
        }
    }
}
</script>